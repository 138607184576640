import './App.css';
import "bootstrap/dist/css/bootstrap.min.css"
import Router from './components/Router';



function App() {

  return (
    <div className='App'>
      <Router/>
  </div>
    );
}

export default App;

